import { AccountManager } from '@core/core.types';

export class SetAccountManagersAction {
  static type = '[AccountManagers] SetAccountManagersAction';
  constructor(public accountManagers: AccountManager[]) {}
}

export class LoadAccountManagersAction {
  static type = '[AccountManagers] LoadAccountManagersAction';
}

export class LoadAccountManagerAction {
  static type = '[AccountManagers] LoadAccountManagerAction';
  constructor(public id: string) {}
}

export class GenerateAccountManagerAction {
  static type = '[AccountManagers] GenerateAccountManagerAction';
  constructor(
    public accountManager: AccountManager,
    public subAccountManagers?: string[]
  ) {}
}

export class UpdateAccountManagerAction {
  static type = '[AccountManagers] UpdateAccountManagerAction';
  constructor(
    public accountManager: AccountManager,
    public subAccountManagers?: string[]
  ) {}
}

export class ActivateAccountManagerAction {
  static type = '[AccountManagers] ActivateAccountManagerAction';
  constructor(public id: string) {}
}

export class DeactivateAccountManagerAction {
  static type = '[AccountManagers] DeactivateAccountManagerAction';
  constructor(public id: string) {}
}

export class AssignCountryToAccountManagerAction {
  static type = '[AccountManagers] AssignCountryToAccountManagerAction';
  constructor(
    public id: string,
    public countryName: string
  ) {}
}

export class UnassignCountryFromAccountManagerAction {
  static type = '[AccountManagers] UnassignCountryFromAccountManagerAction';
  constructor(
    public id: string,
    public countryName: string
  ) {}
}

export class UpdateSubAccountManagers {
  static type = '[AccountManagers] UpdateSubAccountManagers';
  constructor(
    public id: string,
    public subAccountManagers: string[]
  ) {}
}

export class AssignSubAccountManager {
  static type = '[AccountManagers] AssignSubAccountManager';
  constructor(
    public id: string,
    public subAccountManager: string
  ) {}
}

export class UnassignSubAccountManager {
  static type = '[AccountManagers] UnassignSubAccountManager';
  constructor(
    public id: string,
    public subAccountManager: string
  ) {}
}
